import React, { useState, useEffect } from 'react';
import ReactDOM from "react-dom/client";
import { Transition } from '@headlessui/react';
import Message, { MessageType } from './Message';
import ncNanoId from 'utils/ncNanoId';
export interface MessageApi {
    info: (text: string, options: OptionsType) => void;
    success: (text: string, options: OptionsType) => void;
    warning: (text: string, options: OptionsType) => void;
    error: (text: string, options: OptionsType) => void;
}

export interface Notice {
    text: string;
    key: string;
    type: MessageType;
}

export interface OptionsType {
    timeout?: number
    
}

let add: (notice: Notice, options: OptionsType) => void

export const MessageContainer = () => {
    const [notices, setNotices] = useState<Notice[]>([]);

    const maxCount = 10;

    const remove = (notice: Notice) => {
        const { key } = notice;
        setNotices((prevNotices) => (
            prevNotices.filter(({ key: itemKey }) => key !== itemKey)
        ))
    }

    add = (notice: Notice, { timeout } = { timeout: 3000 }) => {
        setNotices(prevNotices => [...prevNotices, notice])
        setTimeout(() => {
            remove(notice)
        }, timeout)
    }

    useEffect(() => {
        if (notices.length > maxCount) {
            const [firstNotice] = notices;
            remove(firstNotice)
        }
    }, [notices])

    return (
        <div className='fixed top-0 left-0 w-screen z-50'>
            {
                notices.map(({ type, text, key }) => (
                    <Transition
                        as={'div'}
                        appear={true}
                        unmount
                        key={key}
                        show
                        className='opacity-0 translate-y-full pointer-events-none'
                        enter="transition transform duration-700"
                        enterFrom='opacity-0 -translate-y-full pointer-events-none'
                        enterTo='opacity-1 translate-y-full'
                        leave="transition-all transition-transform duration-1000"
                        leaveFrom='opacity-1 translate-y-full'
                        leaveTo='opacity-0 -translate-y-full'
                    >
                        <Message type={type} text={text} />
                    </Transition>
                ))
            }
        </div>
    )
}

let el = document.querySelector('#message-wrapper');
if (!el) {
    el = document.createElement('div')
    el.className = 'message-wrapper'
    el.id = 'message-wrapper'
    document.body.appendChild(el);
}

const message = ReactDOM.createRoot(
    document.getElementById("message-wrapper") as HTMLElement
);
message.render(<MessageContainer />)

const api: MessageApi = {
    success(text, options) {
        add({
            text,
            key: ncNanoId('message_'),
            type: 'success'
        }, options)
    },
    warning(text, options) {
        add({
            text,
            key: ncNanoId('message_'),
            type: 'warning'
        }, options)
    },
    error(text, options) {
        add({
            text,
            key: ncNanoId('message_'),
            type: 'error'
        }, options)
    },
    info(text, options) {
        add({
            text,
            key: ncNanoId('message_'),
            type: 'info'
        }, options)
    },
}
export default api