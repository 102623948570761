import mediaRunningReducer from "./mediaRunning/mediaRunning";
import userInfoReducer from "./userInfo/userInfo";
import cartReducer from './userInfo/cart';
import navigationReducer from "./navigation/navigation";
import api from './services';


const rootReducers = {
  mediaRunning: mediaRunningReducer,
  userInfo: userInfoReducer,
  cart: cartReducer,
  navigation: navigationReducer,
  [api.reducerPath]: api.reducer,
};

export default rootReducers;
