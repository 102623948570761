import React, { useState, Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import NavMobile from "shared/Navigation/NavMobile";
import { NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";
import { useTranslation } from 'react-i18next';

export interface MenuBarProps { }
const MenuBar: React.FC<MenuBarProps> = () => {
  const [isVisable, setIsVisable] = useState(false);
  const handleOpenMenu = () => setIsVisable(true);
  const handleCloseMenu = () => setIsVisable(false);
  const { t } = useTranslation();
  const { home, goods, replica, collection, gallery, author } = t('nav', { returnObjects: true }) as any
  const {
    print,
    canvas,
    handbag,
    TShirt,
    sweater,
    phoneShell
  } = t('goodsChildren',{returnObjects:true}) as any
  const otherPageChildMenus: NavItemType[] = [
    {
      id: ncNanoId(),
      href: "/",
      name: home,
    },
    {
      id: ncNanoId(),
      href: "/page-commodity",
      name: goods,
      children: [
        {
          id: ncNanoId(),
          href: "/page-print",
          name:print,
        },
        {
          id: ncNanoId(),
          href: "/page-canvas-print",
          name: canvas,
        },
        {
          id: ncNanoId(),
          href: "/page-handbag",
          name: handbag,
        },
        {
          id: ncNanoId(),
          href: "/page-tShirt",
          name: TShirt,
        },
        {
          id: ncNanoId(),
          href: "/page-sweater",
          name: sweater,
        },
        {
          id: ncNanoId(),
          href: "/page-phone-shell",
          name: phoneShell,
        },
      ]
    },
    {
      id: ncNanoId(),
      href: "/page-replica",
      name: replica,
    },
    {
      id: ncNanoId(),
      href: "/page-collection",
      name: collection,
    },
    {
      id: ncNanoId(),
      href: "/atr-gallery",
      name: gallery,
    },
    {
      id: ncNanoId(),
      href: "/become-author",
      name: author,
    }
  ];

  const data: NavItemType[] = [
    {
      id: ncNanoId(),
      href: "/#",
      name: t('navigation'),
      type: "dropdown",
      children: otherPageChildMenus,
    },
    {
      id: ncNanoId(),
      href: "/contact",
      name: t('help'),
    },
  ];
  const renderContent = () => {
    return (
      <Transition appear show={isVisable} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={handleCloseMenu}
        >
          <div className="fixed left-0 top-0 bottom-0 w-full md:w-auto z-max outline-none focus:outline-none">
            <React.Fragment>
              <Transition.Child
                as={Fragment}
                enter="transition duration-100 transform"
                enterFrom="opacity-0 -translate-x-14"
                enterTo="opacity-100 translate-x-0"
                leave="transition duration-150 transform"
                leaveFrom="opacity-100 translate-x-0"
                leaveTo="opacity-0 -translate-x-14"
              >
                <div className="z-10 relative">
                  <NavMobile onClickClose={handleCloseMenu} data={data} />
                </div>
              </Transition.Child>

              <Transition.Child
                as={Fragment}
                enter=" duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave=" duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-neutral-900 bg-opacity-50" />
              </Transition.Child>
            </React.Fragment>
          </div>
        </Dialog>
      </Transition>
    );
  };

  return (
    <>
      <button
        onClick={handleOpenMenu}
        className="p-2.5 rounded-lg text-neutral-700 dark:text-neutral-300 focus:outline-none flex items-center justify-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-7 w-7"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      {renderContent()}
    </>
  );
};

export default MenuBar;
