import React, { FC,ReactNode } from "react";

export interface CheckboxProps {
  label?: string | ReactNode;
  subLabel?: string | number;
  subLabelClassName?:string;
  className?: string;
  name: string;
  defaultChecked?: boolean;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
}

const Checkbox: FC<CheckboxProps> = ({
  subLabel = "",
  label = "",
  name,
  className = "",
  subLabelClassName = "",
  defaultChecked,
  checked,
  onChange,
}) => {
  const controlled = () => (
    <input
      id={name}
      name={name}
      type="checkbox"
      className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
      defaultChecked={defaultChecked}
      onChange={(e) => onChange && onChange(e.target.checked)}
    />
  )

  const unControlled = () => (
    <input
      id={name}
      name={name}
      type="checkbox"
      className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
      checked={checked}
      readOnly
      onChange={(e) => onChange && onChange(e.target.checked)}
    />
  )


  return (
    <div className={`flex text-sm sm:text-base ${className}`}>
      {defaultChecked ? controlled() : unControlled()}
      {label && (
        <label htmlFor={name} className="ml-2.5 sm:ml-3.5 flex flex-col flex-1 justify-center cursor-pointer">
          <span
            className={`text-neutral-900 dark:text-neutral-100 ${!!subLabel ? "-mt-0.5" : ""}`}>
            {label}
          </span>
          {subLabel && (
            <p className={`mt-0.5 text-neutral-500 dark:text-neutral-400 text-sm font-light ${subLabelClassName}`}>
              {subLabel}
            </p>
          )}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
