import { Fragment, useState,FC } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'

const people = [
    { name: '中国大陆', prefix: 86 },
    { name: '中国香港', prefix: 852 },
    { name: '中国澳门', prefix: 853 },
    { name: '中国台湾', prefix: 886 },
    { name: '美国', prefix: 1 },
    { name: '日本', prefix: 81 },
    { name: '英国', prefix: 44 },
    { name: '法国', prefix: 33 },
    { name: '俄罗斯', prefix: 7 },
    { name: '韩国', prefix: 82 },
    { name: '加拿大', prefix: 1 },
    { name: '澳大利亚', prefix: 61 },
    { name: '德国', prefix: 49 },
    { name: '新加坡', prefix: 65 },
    { name: '马来西亚', prefix: 60 },
    { name: '巴西', prefix: 55 },
    { name: '泰国', prefix: 66 },
    { name: '印度', prefix: 91 },
    { name: '新西兰', prefix: 64 },

]
export interface SelectedBoxProps{
    className?:string;
}
const SelectedBox:FC<SelectedBoxProps> = ({
    className = ''
}) => {
    const [selected, setSelected] = useState(people[0])
    return (
        <Listbox value={selected} onChange={setSelected}>
            <div className={className}>
                <Listbox.Button className="relative mt-2 cursor-default w-full bg-white  text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                    <span className="truncate">+ {selected.prefix}</span>
                    <span className="pointer-events-none absolute  inset-y-0 right-3 flex items-center">
                        <ChevronDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                    </span>
                </Listbox.Button>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Listbox.Options className="absolute z-10 mt-1 w-36 max-h-40 text-xs overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {people.map((person, personIdx) => (
                            <Listbox.Option
                                key={personIdx}
                                className={({ active }) =>
                                    `relative cursor-default  select-none py-2 px-2 ${active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                                    }`
                                }
                                value={person}
                            >
                                {({ selected }) => (
                                    <Fragment>
                                        <span
                                            className={`flex justify-between truncate ${selected ? 'font-medium' : 'font-normal'}`}
                                        >
                                            <span>{person.name}</span>
                                            <span>{person.prefix}</span>
                                        </span>
                                    </Fragment>
                                )}
                            </Listbox.Option>
                        ))}
                    </Listbox.Options>
                </Transition>
            </div>
        </Listbox>
    )
}
export default SelectedBox