import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";
import login from 'images/logo.png';
import { useTranslation } from 'react-i18next';
export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const Footer: React.FC = () => {
  const { t } = useTranslation()
  // const {market,company,account,tail:{copyright,treaty,privacy}} = t('footer',{returnObjects:true}) as any;
  // const widgetMenus: WidgetFooterMenu[] = [market,company,account];
  const { market, account, tail: { copyright, treaty, privacy } } = t('footer', { returnObjects: true }) as any;
  const widgetMenus: WidgetFooterMenu[] = [market, account];
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm justify-self-end">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-neutral-6000 dark:text-neutral-100 hover:text-black dark:hover:text-white"
                href={item.href}
                target={item.targetBlank ? '_blank' : ''}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative py-20 lg:pt-32 lg:pb-28 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-1 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-1 lg:grid-cols-4 lg:gap-x-10 ">
        <div className="grid grid-cols-4 gap-5 grid-rows-2 col-span-2 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1">
            <Logo img={login} />
          </div>
          <div className="col-span-2 flex items-center md:col-span-3">
            <SocialsList1 className="flex items-center space-x-2 lg:space-x-0 lg:flex-col lg:space-y-3 lg:items-start" />
          </div>
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
        <div className="flex col-span-4 lg:visible sm:invisible  justify-between text-slate-400">
          <div>{copyright}</div>
          <div>
            <span className="mr-4">{treaty}</span>
            <span className="ml-4">{privacy}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
