import React, { FC } from 'react'
import { CheckIcon, InformationCircleIcon, ShieldExclamationIcon, XIcon } from '@heroicons/react/outline';

export type MessageType = 'info' | 'success' | 'error' | 'warning'
export interface MessageProps {
    text: string;
    type: MessageType;
}
const Message: FC<MessageProps> = ({
    text,
    type
}) => {
    const renderIcon = (messageType: MessageType) => {
        let messageIcon
        switch (messageType) {
            case 'success': //成功
                messageIcon = <CheckIcon className='w-5 h-5 bg-green-500 rounded-full text-white' />
                break;
            case 'error': //错误
                messageIcon = <XIcon className='w-5 h-5 bg-red-500 rounded-full text-white' />;
                break;
            case 'warning': //警告
                messageIcon = <ShieldExclamationIcon className='w-5 h-5 bg-yellow-500 rounded-full text-white' />;
                break;
            case 'info': //默认
                messageIcon = <InformationCircleIcon className='w-5 h-5 bg-cyan-500 rounded-full text-white' />;
                break;
            default:
                messageIcon = <InformationCircleIcon className='w-5 h-5 bg-cyan-500 rounded-full text-white' />;
                break;
        }
        return messageIcon;
    }
    return (
        <div className='flex justify-center items-center'>
            <div className='bg-white shadow-md flex p-3'>
                <i className='pr-2'>{renderIcon(type)}</i>
                <span className='text-slate-700'>{text}</span>
            </div>
        </div>
    )
}

export default Message