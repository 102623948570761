import React from "react";
import NavigationItem from "./NavigationItem";
import { NavItemType } from "shared/Navigation/NavigationItem";
interface NavigationType {
  navArr?: NavItemType[]
}

const Navigation: React.FC<NavigationType> = ({
  navArr = []
}) => {
  return (
    <ul className="nc-Navigation  w-full justify-between hidden lg:flex lg:flex-wrap lg:items-center lg:space-x-1 relative">
      {navArr.map((item) => (
        <NavigationItem key={item.id} menuItem={item} />
      ))}
    </ul>
  );
}

export default Navigation;
