import Message from 'components/Message';
import api from '.';
import { setUserInfo, setLoginOpen, removeUserInfo } from 'app/userInfo/userInfo';

export interface PhoneSendType {
    phone: string
}
export interface VerificationCode {
    code: number;
    data: number
}

export interface VerificationLoginType {
    code: number;
    msg: string;
    data: null | object;
    userId: number;
    user: UserType;
    tokenInfo: TokenInfoType;
}

export interface LoginType {
    phone: string;
    code: string;
}

export interface LandType {
    name: string;
    pwd: string;
}


const userApi = api.injectEndpoints({
    endpoints: (build) => ({
        login: build.mutation<VerificationLoginType, any>({ //登陆
            async queryFn(arg, queryApi, extraOptions, baseQuery) {
                const payload = await baseQuery({
                    url: process.env.REACT_APP_USER_LOGIN!,
                    method: "POST",
                    body: arg,
                    headers: [['tagcli', '1006'], ['Content-Type', 'application/json']]
                }) as { data: UserResponseType }

                const user = payload.data.user;
                const tokenInfo = payload.data.tokenInfo
                if (payload.data.code === 200) {
                    Message.success(`欢迎 ${payload.data.user.username}`, { timeout: 1000 });
                    const options = setUserInfo({ user, tokenInfo })
                    queryApi.dispatch(options);
                    queryApi.dispatch(setLoginOpen(false));
                } else {
                    Message.error('登录失败', { timeout: 1000 })
                }
                return payload;
            }
        }),
        obtainCode: build.mutation<VerificationCode, any>({ //获取验证码
            async queryFn(arg, queryApi, extraOptions, baseQuery) {
                const payload = await baseQuery({
                    url: process.env.REACT_APP_USER_OBTAINCODE!,
                    method: "POST",
                    body: arg,
                    headers: [['tagcli', '1006'], ['Content-Type', 'application/json']]
                }) as { data: any }
                if (payload.data.code === 200) {
                    Message.success('验证码已发送', { timeout: 1000 });
                } else {
                    Message.error(payload.data.msg, { timeout: 1000 })
                }
                return payload;
            }
        }),
        codelogin: build.mutation<VerificationLoginType, any>({ //验证码登陆
            async queryFn(arg, queryApi, extraOptions, baseQuery) {
                const payload = await baseQuery({
                    url: process.env.REACT_APP_USER_CODELOFIN!,
                    method: "POST",
                    body: arg,
                    headers: [['tagcli', '1006'], ['Content-Type', 'application/json']]
                }) as { data: UserResponseType }
                const user = payload.data.user;
                const tokenInfo = payload.data.tokenInfo
                if (payload.data.code === 200) {
                    Message.success(`欢迎 ${payload.data.user.username}`, { timeout: 1000 });
                    const options = setUserInfo({ user, tokenInfo })
                    queryApi.dispatch(options);
                    queryApi.dispatch(setLoginOpen(false));
                } else {
                    Message.error('登录失败', { timeout: 1000 })
                }
                return payload;
            }
        }),
        obtainQr: build.mutation<VerificationCode, any>({ //获取二维码
            async queryFn(arg, queryApi, extraOptions, baseQuery) {
                const payload = await baseQuery({
                    url: process.env.REACT_APP_USER_QRCODE!,
                    method: "GET",

                    headers: [['tagcli', '1006'], ['Content-Type', 'application/json']]
                }) as { data: any }
                if (payload.data.code === 200) {
                  
                } else {
                    Message.error(payload.data.msg, { timeout: 1000 })
                }
                return payload;
            }
        }),
        qrlogin: build.mutation<VerificationLoginType, any>({ //二维码登录
            async queryFn(arg, queryApi, extraOptions, baseQuery) {
                const payload = await baseQuery({
                    url: process.env.REACT_APP_USER_OBTAINSCAN!,
                    method: "POST",
                    body: arg,
                    headers: [['tagcli', '1006'], ['Content-Type', 'application/json']]
                }) as { data: UserResponseType }
                const user = payload.data.user;
                const tokenInfo = payload.data.tokenInfo
              
                if (payload.data.code === 200) {
                    Message.success(`欢迎 ${payload.data.user.username}`, { timeout: 1000 });
                    const options = setUserInfo({ user, tokenInfo })
                    queryApi.dispatch(options);
                    queryApi.dispatch(setLoginOpen(false));
                }
                return payload;
            }
        }),

        autoFrontAuth: build.query<any, void>({
            async queryFn(arg, api, extraOptions, baseQuery) {
                const state = api.getState() as { userInfo: { tokenInfo: TokenInfoType } };
                const tokenInfo = state.userInfo.tokenInfo
                if (tokenInfo.tokenName && tokenInfo.tokenValue) {
                    const payload = await baseQuery({
                        url: process.env.REACT_APP_USER_FRONTAUTH!,
                        method: "GET",
                        headers: [[tokenInfo.tokenName, tokenInfo.tokenValue]]
                    }) as { data: Omit<UserResponseType, "userId" | "user" | "tokenInfo"> }
                    if (payload.data.code !== 200) {
                        api.dispatch(removeUserInfo());
                    }
                    return payload;
                }
                return { data: "null" }

            },
        }),

    })

})


export const {
    useLoginMutation,
    useAutoFrontAuthQuery,
    useObtainCodeMutation,
    useObtainQrMutation,
    useQrloginMutation,
    useCodeloginMutation
} = userApi;
