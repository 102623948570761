import {  useState, FC, memo, useRef, ElementRef } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from 'shared/Input/Input';
import countDown from "utils/countDown";
import SelectListbox from "components/SelectedBox/SelectedBox";
import Checkbox from 'shared/Checkbox/Checkbox';

type verificationType = {
    phone: string,
    code: string
}

export interface LoginOpenProps {

    closeModal?: () => void
    verificationTrigger?: (value: string) => void
    verifyNow?: (options: verificationType) => void
    isLoading?: boolean
}
const phoneRegex: RegExp = /^(13[0-9]|14[5|7|9]|15[0|1|2|3|5|6|7|8|9]|16[6]|17[0|1|2|3|5|6|7|8]|18[0-9]|19[8|9])\d{8}$/; //手机号正则
const PhoneLogin: FC<LoginOpenProps> = memo(({
    verificationTrigger = () => { },
    verifyNow = () => { },
    isLoading = false
}) => {
    const { t } = useTranslation();
    const phoneRef = useRef<ElementRef<typeof Input>>(null);
    const codeRef = useRef<ElementRef<typeof Input>>(null);
    const [noteTime, setNoteTime] = useState<string>('获取短信验证码');
    const [isTreaty, setIsTreaty] = useState<boolean>(true); //复选框选中状态
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const phoneChange = (): void => { //获取输入框的值
        if (isDisabled) return;
        const value: any = phoneRef.current?.value;
        if (phoneRegex.test(value) && isTreaty) {
            setIsDisabled(true)
            verificationTrigger(value)
            countDown((num: number) => {
                setNoteTime(`${num}秒后再获取`)
            }, 60, () => {
                setNoteTime('请重新获取验证码')
                setIsDisabled(false)
            });
        }
    }

    const handleVerifyNow = () => {
        const phone = phoneRef.current?.value as string;
        const code = codeRef.current?.value;
        if (isTreaty && phoneRegex.test(phone) && code) {
            verifyNow({
                phone,
                code
            })
        }
    }
    return (
        <form className="grid grid-cols-1 gap-6">
            <label className="block relative">
                {/* <span className="text-neutral-800 dark:text-neutral-200">
                    {t('loginIn.telePhone')}
                </span> */}
                <div className="relative flex">
                    <SelectListbox className="w-24 pl-3 mt-1 absolute top-1 left-1 h-4/5" />
                    <Input
                        type="number"
                        placeholder={t('loginIn.plPhone')}
                        className="mt-1 pl-28 no-arrow truncate dark:bg-white dark:border-neutral-400"
                        ref={phoneRef}
                        disabled={isDisabled}
                        onChange={phoneChange}
                    />
                </div>
            </label>
            <label className="block relative">
                <div className="relative flex">
                    <Input
                        type="number"
                        ref={codeRef}
                        placeholder={t('loginIn.verification')}
                        className="no-arrow truncate dark:bg-white dark:border-neutral-400"

                    />
                    <div className="w-2/5  dark:border-neutral-400 absolute top-0 right-0 text-blue-600 h-full leading-10 rounded-2xl text-sm rounded-l-none border-neutral-200 border text-center">
                        <span onClick={phoneChange} className="hover:cursor-pointer">{noteTime}</span>
                    </div>
                </div>
            </label>
            <Checkbox
                label={<span className='dark:text-black'>我已阅读并同意<span className='text-red-700'>《用户服务协议》《隐私政策》</span></span>}
                name='checked'
                subLabel='未注册的手机号将自动生成新账号'
                defaultChecked={isTreaty}
                subLabelClassName="dark:text-black"
                onChange={setIsTreaty}
            />
            {/* <div className='text-center'>账号登陆</div> */}
            <ButtonPrimary type="button" loading={isLoading} onClick={handleVerifyNow} >立即验证</ButtonPrimary>
        </form>
    )
})
export default PhoneLogin