import { useState, Fragment } from 'react'
import { Dialog, Transition, Tab } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import LoginOpen from "components/PhoneLogin/PhoneLogin";
import AccountLogin from './AccountLogin';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectUserInfo, setLoginOpen } from 'app/userInfo/userInfo';

import ncNanoId from 'utils/ncNanoId';
const Login = () => {
  const isLoginOpen = useAppSelector(selectUserInfo).isLoginOpen

  const dispatch = useAppDispatch();
  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }
  const categories = [
    {
      id: ncNanoId('tab_'),
      tabTitle: '电话登录'
    },
    {
      id: ncNanoId('tab_'),
      tabTitle: '账号登录'
    }
  ]
  function closeModal() {
    dispatch(setLoginOpen(false))
  }

  return (
    <>
      <Transition appear show={isLoginOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full relative max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg text-center font-medium leading-6 text-gray-900">
                    请登录
                  </Dialog.Title>
                  <XIcon className='absolute w-5 h-5 top-4 hover:cursor-pointer right-4 text-slate-500' onClick={closeModal} />
                  <div className="mt-2">
                    <Tab.Group>
                      <Tab.List className='flex space-x-1 rounded-xl bg-blue-900/20 p-1'>
                        {
                          categories.map(item => (
                            <Tab
                              key={item.id}
                              className={({ selected }) =>
                                classNames(
                                  'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                                  selected
                                    ? 'bg-white shadow'
                                    : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                                )}
                            > {item.tabTitle}
                            </Tab>
                          ))
                        }
                      </Tab.List>
                      <Tab.Panels>
                        <Tab.Panel>
                          <LoginOpen />
                        </Tab.Panel>
                        <Tab.Panel>
                          <AccountLogin hideLabel={true} />
                        </Tab.Panel>
                      </Tab.Panels>
                    </Tab.Group>
                   
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default Login