
// import Route from "./router";
import { useRoutes } from 'react-router-dom';
import { useMemo } from 'react';
import { baseRouteView, userRoutesView, syncRouter } from './route';
import Footer from "shared/Footer/Footer";
import SiteHeader from "containers/SiteHeader";
import { selectUserInfo } from 'app/userInfo/userInfo';
import { useAppSelector } from 'app/hooks';
//component 默认情况下TransitionGroup会被渲染为div,如果想要避免。可以传入null 表示什么都不渲染
//appear 控制子组件是否展示 入场动画，默认为 true，它接收的值会覆盖掉子组件上对应的设置
//enter 控制子组件是否展示 开始动画，默认为 true，它接收的值会覆盖掉子组件上对应的设置
//exit 控制子组件是否展示 结束动画，默认为 true，它接收的值会覆盖掉子组件上对应的设置
// const RoutesIndex = () => {
//   const location = useLocation();
//   return (
//     <Fragment>
//       <SiteHeader />
//       <TransitionGroup component={null}>
//           <CSSTransition
//             timeout={2500}
//             unmountOnExit
//             key={location.pathname}
//             classNames={{
//               enter: "in-enter", //表示动画初始阶段
//               enterActive: "in-enter-active", //表示动画激活阶段
//               enterDone: "", //表示动画结束阶段 也是样式的持久化展示阶段
//               exit: "out-exit",
//               exitActive: "out-exit-active",
//             }}
//           >
//             <Route />
//           </CSSTransition>
//       </TransitionGroup>
//       <Footer />
//     </Fragment>
//   );
// };

const Router = () => {
  const isLogin = useAppSelector(selectUserInfo).tokenInfo.isLogin;
  const route = useMemo(() => isLogin ? [...baseRouteView, ...userRoutesView] : baseRouteView, [isLogin]);

  return (
    <>
      <SiteHeader />
      {useRoutes(syncRouter(route))}
      <Footer />
    </>
  )
}
export default Router;
