import React, { FC, useState, useRef } from "react";
import { useForm, SubmitHandler } from 'react-hook-form';
import Input from "shared/Input/Input";
import { Link, useLocation, useNavigate } from "react-router-dom";

import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useTranslation } from 'react-i18next';
import { useLoginMutation, useObtainCodeMutation, useCodeloginMutation, useObtainQrMutation, useQrloginMutation } from 'app/services/user';
import Checkbox from 'shared/Checkbox/Checkbox';
import Message from "components/Message";
import QRCode from 'qrcode';
import refresh from '../images/refresh.png';
export type LoginType = {
    name: string;
    pwd: string;
    phone: string;
    code: string;
}
export type AccountLoginProps = {
    hideLabel?: boolean
}

const AccountLogin: FC<AccountLoginProps> = ({
    hideLabel = false
}) => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const {
        username,
        plPassword,
        password,
        forgotPs,
        determine
    } = t('loginIn', { returnObjects: true }) as any;
    const [isTreaty, setIsTreaty] = useState<boolean>(true); //复选框选中状态
    const [phoneIsTreaty, setPhoneIsTreaty] = useState<boolean>(true); //手机号复选框选中状态
    const [flag, setFlag] = useState<number>(0); //登录选项  0:账号密码登录  1:短信验证码登录 2.扫码登录
    const [code, setCode] = useState<string>(''); //验证码
    const [phone, setPhone] = useState<string>(''); //手机号
    const qrcodeRef = useRef(null); //二维码
    const [refreshflag, setRefreshflag] = useState<boolean>(false)
    const [loginMutation, result] = useLoginMutation({
        fixedCacheKey: 'login'
    });
    const [obtainCodeMutation, result1] = useObtainCodeMutation({
        fixedCacheKey: 'obtainCode'
    });
    const [codeloginMutation, result2] = useCodeloginMutation({
        fixedCacheKey: 'codelogin'
    });
    const [obtainQrMutation, result3] = useObtainQrMutation({
        fixedCacheKey: 'obtainQr'
    })
    const [qrloginMutation, result4] = useQrloginMutation({
        fixedCacheKey: 'qrlogin'
    })


    const { register, handleSubmit, formState: { errors }, reset } = useForm<LoginType>();

    const loginMethod = (num: number) => { //登录方式切换
        setFlag(num);
        if (num === 2) {
            obtainQr()
        }
        reset();
    }

    const handleEditSubmit: SubmitHandler<LoginType> = async (data) => { //账号密码登录
        if (!isTreaty) {
            Message.info("请勾选协议!", { timeout: 1000 });
            return;
        }
        const result = await loginMutation({ "telStr": data.name, "pwdStr": data.pwd }).unwrap();
        if (result.code === 200 && location.pathname === '/login') {
            navigate('/');
        }
        reset();
    }



    const codeLogin = async () => { //短信验证码登录
        if (!phoneIsTreaty) {
            Message.info("请勾选协议!", { timeout: 1000 });
            return;
        }
        const res: any = await codeloginMutation({ "telStr": phone, "vcStr": code });
        console.log(res.data.code);
        if (res.data.code === 200 && location.pathname === '/login') {
            navigate('/');
        }
        reset();
    }

    const qrlogin = async (qid: string) => { //获取扫码结果
        const interval = setInterval(async () => {
            const res: any = await qrloginMutation({ "qid": qid })
            console.log("二维码登录结果===========>", res);
            if (res.data.code === 200 && location.pathname === '/login') {
                clearInterval(interval);
                navigate('/');
            }

        }, 3000);

        setTimeout(() => {
            setRefreshflag(true)
            clearInterval(interval);
        }, 180000);

        return () => {
            clearInterval(interval);
        };
    }



    const obtainCode = async () => { //获取验证码
        const res = await obtainCodeMutation({ "telStr": phone });
    }

    const obtainQr = async () => {  //获取二维码
        const res: any = await obtainQrMutation({})
        if (res.data.scanImg && qrcodeRef.current) {
            QRCode.toCanvas(qrcodeRef.current, res.data.scanImg, { width: 400 }, (error) => {
                if (error) {
                    console.error('Error generating QR code:', error);
                }
            });
            qrlogin(res.data.scanImg)
        }
    }

    const refreshQr = () => { //刷新二维码
        obtainQr()
        setRefreshflag(false)
    }
    return (
        <>
            {
                flag === 0 ? (
                    <div className="flex justify-between items-center mt-4">
                        <span className="h-px bg-neutral-300 dark:bg-neutral-700 flex-grow mr-3" />
                        <button className="text-base text-[#0284c7] dark:text-neutral-400 pr-2 hover:text-red-600" onClick={() => { loginMethod(1) }}  >验证码登录 </button>
                        <button className="text-base text-[#0284c7] dark:text-neutral-400 hover:text-red-600" onClick={() => { loginMethod(2) }}  >扫码登录</button>
                        <span className="h-px bg-neutral-300 dark:bg-neutral-700 flex-grow ml-3" />
                    </div>
                ) : (
                    <></>
                )
            }
            {
                flag === 1 ? (
                    <div className="flex justify-between items-center mt-4">
                        <span className="h-px bg-neutral-300 dark:bg-neutral-700 flex-grow mr-3" />
                        <button className="text-base text-[#0284c7] dark:text-neutral-400 pr-2 hover:text-red-600" onClick={() => { loginMethod(0) }}  >账号密码登录</button>
                        <button className="text-base text-[#0284c7] dark:text-neutral-400 hover:text-red-600" onClick={() => { loginMethod(2) }}  >扫码登录</button>
                        <span className="h-px bg-neutral-300 dark:bg-neutral-700 flex-grow ml-3" />
                    </div>
                ) : (
                    <></>
                )
            }
            {
                flag === 2 ? (
                    <div className="flex justify-between items-center mt-4">
                        <span className="h-px bg-neutral-300 dark:bg-neutral-700 flex-grow mr-3" />
                        <button className="text-base text-[#0284c7] dark:text-neutral-400 pr-2 hover:text-red-600" onClick={() => { loginMethod(0) }}  >账号密码登录</button>
                        <button className="text-base text-[#0284c7] dark:text-neutral-400 hover:text-red-600" onClick={() => { loginMethod(1) }}  >验证码登录 </button>
                        <span className="h-px bg-neutral-300 dark:bg-neutral-700 flex-grow ml-3" />
                    </div>
                ) : (
                    <></>
                )
            }
            {
                flag === 0 ? (
                    <form onSubmit={handleSubmit(handleEditSubmit)} className="grid grid-cols-1 gap-6">
                        <label className="block relative">
                            {
                                !hideLabel &&
                                (<span className="text-neutral-800 dark:text-neutral-200">
                                    {username}
                                </span>)
                            }
                            <Input
                                type="text"
                                placeholder={username}
                                className="mt-1 no-arrow dark:bg-white dark:border-neutral-400 "
                                {...register('name', { required: true })}
                            />
                            <span className={`text-xs left-4 -bottom-5 origin-top scale-y-0 transition-transform absolute text-red-600 ${errors.name?.type === 'required' && 'scale-y-100'}`}>
                                {errors.name?.type === 'required' && '请输入用户名'}
                            </span>
                        </label>
                        <label className="block relative">
                            {
                                !hideLabel &&
                                (<span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                                    {password}
                                    <Link to="/forgot-pass" className="text-sm text-green-600">
                                        {forgotPs}
                                    </Link>
                                </span>)
                            }
                            <Input
                                type="password"
                                className="mt-1 dark:bg-white dark:border-neutral-400"
                                placeholder={plPassword}
                                {...register('pwd', { required: true })}
                            />
                            <span className={`text-xs left-4 -bottom-5 origin-top scale-y-0 transition-transform absolute text-red-600 ${errors.name?.type === 'required' && 'scale-y-100'}`}>
                                {errors.pwd?.type === 'required' && '请输入密码'}
                            </span>
                        </label>
                        <Checkbox
                            label={<span className='dark:text-black'>我已阅读并同意<span className='text-red-700'>《用户服务协议》《隐私政策》</span></span>} name='checked'
                            defaultChecked={isTreaty}
                            onChange={setPhoneIsTreaty}
                        />

                        <ButtonPrimary loading={result.isLoading} type="submit">{determine}</ButtonPrimary>
                    </form>) : (
                    <></>
                )
            }
            {
                flag === 1 ? (
                    <form onSubmit={handleSubmit(codeLogin)} className="grid grid-cols-1 gap-6">
                        <label className="block relative">
                            <span className="text-neutral-800 dark:text-neutral-200">手机号</span>
                            <Input
                                type="text"
                                placeholder={"请输入手机号"}
                                className="mt-1 no-arrow dark:bg-white dark:border-neutral-400 "
                                {...register('phone', { required: true })}
                                onChange={(e) => {
                                    setPhone(e.target.value);
                                }}
                            />
                            <span className={`text-xs left-4 -bottom-5 origin-top scale-y-0 transition-transform absolute text-red-600 ${errors.name?.type === 'required' && 'scale-y-100'}`}>
                                {errors.phone?.type === 'required' && '请输入手机号'}
                            </span>
                        </label>
                        <label className="block relative">
                            <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                                验证码
                                <div className="text-sm text-green-600">
                                    <span onClick={obtainCode}>获取验证码</span>
                                </div>
                            </span>
                            <Input
                                type="password"
                                className="mt-1 dark:bg-white dark:border-neutral-400"
                                placeholder={"请输入验证码"}
                                {...register('code', { required: true })}
                                onChange={(e) => {
                                    setCode(e.target.value);
                                }}
                            />
                            <span className={`text-xs left-4 -bottom-5 origin-top scale-y-0 transition-transform absolute text-red-600 ${errors.name?.type === 'required' && 'scale-y-100'}`}>
                                {errors.code?.type === 'required' && '请输入验证码'}
                            </span>
                        </label>
                        <Checkbox
                            label={<span className='dark:text-black'>我已阅读并同意<span className='text-red-700'>《用户服务协议》《隐私政策》</span></span>} name='checked'
                            defaultChecked={isTreaty}
                            onChange={setIsTreaty}
                        />

                        <ButtonPrimary loading={result2.isLoading} type="submit">登录</ButtonPrimary>
                    </form>
                ) : (
                    <></>
                )
            }
            {
                flag === 2 ? (
                    <div>
                        <div className="flex justify-center relative">
                            <canvas ref={qrcodeRef} />
                            {
                                refreshflag ? <img className="w-[400] absolute" src={refresh} alt="" onClick={() => { refreshQr() }} /> : <></>
                            }
                        </div>

                        <p className="flex justify-center">请使用手机APP扫码登录</p>
                    </div>
                ) : (
                    <></>
                )
            }


        </>
    )
}

export default AccountLogin