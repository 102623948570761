
const countDown = (callback:Function,num:number,success:Function):void=>{
    const timer = setInterval(()=>{
        num--;
        callback(num)
        if(num <= 0) {
            clearInterval(timer)
            success()
        }
    },1000)
}



export default countDown