import { FC, useEffect, useState, useCallback } from "react";
import throttle from 'utils/throttle';
import { useLocation } from 'react-router-dom';
import NcImage from "shared/NcImage/NcImage";
import MainNav2 from "./MainNav2";
import toTop from 'images/toTop.png';
import { Transition } from '@headlessui/react';
import { selectNavigation } from 'app/navigation/navigation';
import { useAppSelector} from 'app/hooks';
let newState: boolean = true;
export interface Header2Props {

}

const Header2: FC<Header2Props> = ({

}) => {
  const [isFixed, setIsFixed] = useState<boolean>(false);
  const location = useLocation();
  const navigation = useAppSelector(selectNavigation);
  
  
  const handleScroll = throttle(() => {
    const scrollTop = document.documentElement.scrollTop;
    if (scrollTop > 1500 && newState) {
      setIsFixed(true);
      newState = false;
    } else if (scrollTop < 1500 && !newState) {
      setIsFixed(false)
      newState = true;
    }
  }, 500)
  
  const toTopScroll = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, [])
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(toTopScroll, [location])
  return (
    <div className={`nc-Header2  w-full z-40`}>
      <MainNav2 navArr={navigation} />
      <Transition show={isFixed}>
        <Transition.Child
          as='div'
          className="fixed z-40 w-full -top-96 bg-neutral-100 dark:bg-neutral-900"
          enter="transition-all duration-500"
          enterFrom="-top-96"
          enterTo="top-0"
          leave="transition-all duration-500"
          leaveFrom="top-0"
          leaveTo="-top-96"
        >
          <MainNav2 navArr={navigation} />
        </Transition.Child>
        <Transition.Child
          as='div'
          className="fixed z-40 cursor-pointer top-[80%] -right-10"
          enter="transition-all duration-500"
          enterFrom="-right-10"
          enterTo="right-4"
          leave="transition-all duration-500"
          leaveFrom="right-4"
          leaveTo="-right-10"
        >
          <NcImage src={toTop} onClick={toTopScroll} containerClassName='h-10 w-10' className="object-cover w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform" />
        </Transition.Child>
      </Transition>

    </div>
  );
};

export default Header2;
