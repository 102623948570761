function throttle(fn: Function, waitTime: number) { //函数节流
    let timer: any = null;
    return function (...inputInfo: any) {
        //接收的实参（用户输入的内容）
        if (timer) return
        timer = setTimeout(() => {
            fn.apply(null, inputInfo);
            timer = null;
        }, waitTime);
    };
}

//时间戳版本    
export function throttleNow<T>(fn: Function, delay: number) {
    let perTime = Date.now();
    return function (...inputInfo: any) {
        const nowTime = Date.now();
        //如果两次时间间隔超过了指定时间，则执行函数
        if (nowTime - perTime >= delay) {
            perTime = Date.now();
            return fn.apply(null, inputInfo as T)
        }
    }

}

export function debounce<T>(fn: Function, wait: number) {
    let timer: any = null;
    return (...inputInfo: any) => {
        //如果此时存在定时器的话，则取消之前的定时器重新计时
        if (timer) {
            clearInterval(timer);
            timer = null;
        }
        //设置定时器，使时间间隔指定事件后执行
        timer = setTimeout(fn, wait, inputInfo as T);
    }
}

export default throttle