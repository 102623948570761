import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

export type UserInfoStateType = {
    user: UserType;
    tokenInfo: TokenInfoType;
    isLoginOpen: boolean
}

const initialState: UserInfoStateType = {
    user: {
        id: 0,
        username: "",
        avatar: "",
        password: '',
        phone: "",
        email: null,
        status: -1
    },
    tokenInfo: {
        tokenName: "",
        tokenValue: "",
        isLogin: false,
        loginId: '',
        loginType: "",
        tokenTimeout: 0,
        sessionTimeout: 0,
        tokenSessionTimeout: -1,
        tokenActivityTimeout: -1,
        loginDevice: "",
        tag: null
    },
    isLoginOpen: false //登陆弹出层
}
const userInfoSlice = createSlice({
    name: 'userInfo',
    initialState,
    reducers: {
        setUserInfo: (state: any, action: PayloadAction<Omit<UserInfoStateType, 'isLoginOpen'>>) => ({ ...state, ...action.payload }),
        updateUserInfo: (state: any, action: PayloadAction<Omit<UserInfoStateType, 'isLoginOpen'>>) => ({ ...state, ...action.payload }),
        removeUserInfo: (state) => initialState,
        setLoginOpen: (state, action: PayloadAction<boolean>) => {
            const newState = { ...state };
            newState.isLoginOpen = action.payload;
            return newState;
        }
    }

})

export const {
    setUserInfo,
    updateUserInfo,
    removeUserInfo,
    setLoginOpen
} = userInfoSlice.actions


export const selectUserInfo = (state: RootState) => state.userInfo
export default userInfoSlice.reducer;