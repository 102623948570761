import { createSlice, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
const cartAdapter = createEntityAdapter({
    selectId: (goods: CommodityGoodstType) => goods.id
})
const cartSlice = createSlice({
    name: 'cart',
    initialState: cartAdapter.getInitialState(),
    reducers: {
        addCart: (state, action: PayloadAction<CommodityGoodstType>) => {
            const res = state.entities[action.payload.id];
            if (res) {
                action.payload.amount = (res.amount ?? 1) + 1;
                cartAdapter.upsertOne(state, action);
            } else {
                const obj = { ...action.payload }
                obj.amount = 1;
                cartAdapter.addOne(state, obj);
            }
        },
        updateCart: (state, action:PayloadAction<CommodityGoodstType>) => {
            cartAdapter.upsertOne(state, action);
        },
        updateCartAll: (state, action) => {
            cartAdapter.upsertMany(state, action);
        },
        removeCart: cartAdapter.removeOne,
        removeCartAll: cartAdapter.removeAll
    }
})

export const {
    selectEntities: selectCartEntities,
    selectAll: selectAllCart
} = cartAdapter.getSelectors((state: RootState) => state.cart);

export const { addCart, updateCart, removeCart, updateCartAll, removeCartAll } = cartSlice.actions;
export const selectCart = (state: RootState) => state.cart
export default cartSlice.reducer;
