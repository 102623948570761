import {  useState, Fragment} from 'react';
import { Popover, Transition } from '@headlessui/react';
import {useTranslation} from 'react-i18next';
import Radio from "shared/Radio/Radio";

// OK
const Language = () => {
    const [languageOrderStates, setLanguageOrderStates] = useState<string>('chinese');
    const {i18n} = useTranslation();
    const languageArray = [
        { name: "中文", id: "chinese",language:'zh'},
        { name: "en", id: "english",language:'en'},
    ];
    const renderTabsSortOrder = () => {
        return (
            <Popover className="relative">
                {({ open, close }) => (
                    <>
                        <Popover.Button
                            className={`flex items-center justify-center px-4 py-2 text-sm border rounded-full focus:outline-none 
                  ${open ? "!border-primary-500 " : ""}
                    ${!!languageOrderStates.length
                                    ? "!border-primary-500 bg-primary-50 text-primary-900"
                                    : "border-neutral-300 dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                                }
                    `}
                        >
                            <span className="mx-2">
                                {languageOrderStates
                                    ? languageArray.filter((i) => i.id === languageOrderStates)[0]
                                        .name
                                    : "语 言"}
                            </span>
                        </Popover.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <Popover.Panel className="absolute z-40 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-[8rem]">
                                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                                    <div className="relative flex flex-col px-5 py-6 space-y-5">
                                        {languageArray.map((item) => (
                                            <Radio
                                                id={item.id}
                                                key={item.id}
                                                name="radioNameSort"
                                                label={item.name}
                                                defaultChecked={languageOrderStates === item.id}
                                                onChange={(value) => {                                                    
                                                    i18n.changeLanguage(item.language);
                                                    setLanguageOrderStates(value);
                                                    close();
                                                }}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </Popover.Panel>
                        </Transition>
                    </>
                )}
            </Popover>
        );
    };

    return (
        <div>{renderTabsSortOrder()}</div>
    )

}

export default Language