import React, { FC } from "react";
import { useTranslation } from 'react-i18next'
import Logo from "shared/Logo/Logo";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import AvatarDropdown from "./AvatarDropdown";
import Navigation from "shared/Navigation/Navigation";
import { NavItemType } from "shared/Navigation/NavigationItem";
import login from 'images/logo.png';
import Language from "components/Language";
import { useAppSelector } from 'app/hooks';
import { selectUserInfo } from 'app/userInfo/userInfo';
export interface MainNav2Props {
  navArr?: NavItemType[]
}

const MainNav2: FC<MainNav2Props> = ({
  navArr = []
}) => {
  const userInfo = useAppSelector(selectUserInfo);
  const { t, i18n } = useTranslation();
  return (
    <div className={`nc-MainNav2 relative z-10  ${"onTop "}`}>
      <div className="container py-5 relative flex  items-center space-x-4 xl:space-x-8 dark:bg-neutral-900">
        <div className="flex justify-start flex-grow items-center space-x-3 sm:space-x-8 lg:space-x-10">
          <Logo img={login} imgLight={login} className="scale-125" />
          
        </div>
        <div className="text-neutral-700 hidden xl:block w-[60%] dark:text-neutral-100 space-x-1">
          <div className="hidden items-center  w-full xl:flex space-x-2">
            <Navigation navArr={navArr} />
          </div>
        </div>
        <div className="flex items-center  justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
          <div className="hidden items-center  w-full xl:flex space-x-2">
            <div className="hidden sm:block h-10 border-l border-neutral-300 dark:border-neutral-6000"></div>
            <SwitchDarkMode />
            <Language />
            {
              i18n.language === 'zh' ? (
                userInfo.tokenInfo?.isLogin ?
                  (
                    <>
                      <div></div>
                      <AvatarDropdown data={userInfo.user} />
                    </>
                  )
                  : (
                    <ButtonPrimary
                      href={"/login"}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      {t('login')}
                    </ButtonPrimary>
                  )

              ) : (
                <ButtonSecondary
                  href={"/connect-wallet"}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  {t('wallet')}
                </ButtonSecondary>
              )
            }

          </div>
          <div className="flex items-center space-x-1.5 xl:hidden">
            <ButtonPrimary
              href={"/login"}
              sizeClass="px-4 py-2 sm:px-5"
            >
              {t('login')}
            </ButtonPrimary>
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav2;
