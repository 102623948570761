import React from 'react'
import './spin.css'
export default function Spin() {
  return (
    <div className='ant-spin ant-spin-lg ant-spin-spinning' aria-live='polite' aria-busy='true'>
        <span className='ant-spin-dot ant-spin-dot-spin'>
            <i className='ant-spin-dot-item'></i>
            <i className='ant-spin-dot-item'></i>
            <i className='ant-spin-dot-item'></i>
            <i className='ant-spin-dot-item'></i>
        </span>
    </div>
  )
}
