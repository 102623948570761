import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ncNanoId from 'utils/ncNanoId';
import { RootState } from 'app/store';
import { NavItemType } from "shared/Navigation/NavigationItem";
import print from 'images/goods/print@3x.png'; //版画
import duct from 'images/goods/canvasbag@3x.png';
import handbag from 'images/goods/curtain@3x.png';
import tShirt from 'images/goods/T-shirt@3x.png';
import sweater from 'images/goods/hoodies@3x.png';
import phoneShell from 'images/goods/phoneshell@3x.png';

const initialState: NavItemType[] = [
    {
        id: ncNanoId(),
        href: "/",
        alias: '首页',
        name: "nav.home",
    },
    {
        id: ncNanoId(), //商品导航
        href: "/page-commodity",
        name: 'nav.goods',
        alias: '商品',
        type: 'dropdown',
        children: [
            {
                id: ncNanoId(),
                href: "/page-print",
                name: 'goodsChildren.print',
                alias: "版画",
                targetBlank: true,
                icon: print
            },
            {
                id: ncNanoId(),
                href: "/page-canvas-print",
                name: 'goodsChildren.canvas',
                alias: '帆布印花',
                targetBlank: true,
                icon: duct
            },
            {
                id: ncNanoId(),
                href: "/page-handbag",
                name: 'goodsChildren.handbag',
                alias: '窗帘',
                targetBlank: true,
                icon: handbag
            },
            {
                id: ncNanoId(),
                href: "/page-tShirt",
                name: 'goodsChildren.TShirt',
                alias: 'T恤',
                targetBlank: true,
                icon: tShirt
            },
            {
                id: ncNanoId(),
                href: "/page-sweater",
                name: 'goodsChildren.sweater',
                alias: '卫衣',
                targetBlank: true,
                icon: sweater
            },
            {
                id: ncNanoId(),
                href: "/page-phone-shell",
                name: 'goodsChildren.phoneShell',
                alias: '手机壳',
                targetBlank: true,
                icon: phoneShell
            }
        ]

    },
    {
        id: ncNanoId(),
        href: "/page-replica",
        name: 'nav.replica',
        alias: '复刻品'
    },
    // {
    //     id: ncNanoId(),
    //     href: "/page-collection",
    //     name: 'nav.collection',
    //     alias: '数字藏品'
    // },
    {
        id: ncNanoId(),
        href: "/atr-gallery",
        name: 'nav.gallery',
        alias: '艺术长廊'
    },
    {
        id: ncNanoId(),
        href: "/become-author",
        name: 'nav.author',
        alias: '成为作者'
    },
    {
        id: ncNanoId(),
        href: "/blog",
        name: 'nav.blog',
        alias: '博客'
    },
    // "blog": "博客"
]

const navigation = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        setNavigation: (state, action: PayloadAction<NavItemType[]>) => action.payload
    }
})


export const selectNavigation = (state: RootState) => state.navigation
export const selectNavigationGoodsChildren = (state:RootState) => state.navigation.find(item => item.alias === "商品")?.children
export const { setNavigation } = navigation.actions;
export default navigation.reducer;
