import { useLayoutEffect, Suspense } from 'react';
import Route from "routers/index";
import Login from "containers/Login";
import { useGetGoodsTypeQuery } from 'app/services/goods'
import { useAutoFrontAuthQuery } from 'app/services/user';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectNavigation, setNavigation } from 'app/navigation/navigation';
import { NavItemType } from "shared/Navigation/NavigationItem";
import { BrowserRouter as Router } from "react-router-dom";
import Loading from "containers/Loading";

import './App.css';
function App() {
  useAutoFrontAuthQuery(); //用户检查token
  const { data, isLoading } = useGetGoodsTypeQuery();
  const navigation = useAppSelector(selectNavigation);
  const dispatch = useAppDispatch();
  const toChildren = (children: GoodsType[]) => {
    const goodsArray: NavItemType[] = [];
    let index = -1;
    const options = children.find(item => item.name === "商品");
    const goodsChildren = navigation.find((item, i) => {
      if (item.alias === "商品") {
        index = i;
        return true;
      }
    });
    options?.children?.forEach(item => {
      goodsChildren?.children?.forEach(el => {
        if (item.name === el.alias) {
          let temp = { ...el };
          temp.id = item.id;
          goodsArray.push(temp);
        }
      })
    })
    const newNavigation = [...navigation];
    const temp = { ...newNavigation[index] };
    temp.children = goodsArray;
    newNavigation[index] = temp;
    dispatch(setNavigation(newNavigation));
  }
  useLayoutEffect(() => {
    if (!data) return;
    toChildren(data.data);
  }, [isLoading])

  return (
    <Router basename='/' >
      <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
        <Suspense fallback={<Loading />}>
          <Route />
          <Login />
        </Suspense>
      </div>
    </Router>
  );
}

export default App;
