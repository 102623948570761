import api from ".";
import Message from "components/Message";
interface LikedType {
    data: {
        likeState: "UNLIKE" | "LIKE";
        number: string
    },
    state: string
}
const goodsApi = api.injectEndpoints({ //商品 
    endpoints: (build) => ({
        getGoodsType: build.query<QueryDataType<GoodsType[]>, void>({ //获取商品类型
            query: () => `goods/type`,
        }),
        getGoods: build.mutation<QueryDataType<CommodityType>, GoodsQueryType>({
            query: (options) => ({
                url: options.typeId ? `goods?typeId=${options.typeId}` : 'goods',
                method: 'POST',
                body: options,
            }),
            transformResponse(baseQueryReturnValue: QueryDataType<CommodityType>, meta, arg) {
                if (baseQueryReturnValue.state !== 'ok') {
                    Message.error("数据获取失败", { timeout: 1500 })
                }
                return baseQueryReturnValue;
            },
        }),
        getGoodsDetail: build.query<QueryDataType<CommodityGoodsDetailType>, { type: string; id: string }>({
            query: (options) => `${options.type}/goodsDetail?goodsId=${options.id}`,
            transformResponse(baseQueryReturnValue: QueryDataType<CommodityGoodsDetailType>, meta, arg) {
                return baseQueryReturnValue
            },

        }),
        putProductLikes: build.mutation<{ state: string; msg: string }, { goodsId: string | undefined } & LikeType>({ //商品点赞
            query: (body) => ({
                url: 'goodsUser/collect/save',
                method: 'POST',
                body
            }),
            transformResponse(baseQueryReturnValue: { msg: string; state: string }, meta, arg) {
                Message.info(baseQueryReturnValue.msg, { timeout: 1000 })
                return baseQueryReturnValue
            },
        }),
        getGoodsUserCollectFindNum: build.mutation<LikedType, string>({
            query: (id) => ({
                url: `goodsUser/collect/findNum?goodsId=${id}`,
                method: "GET"
            })
        })

    })
})

export const {
    useGetGoodsTypeQuery,
    useGetGoodsMutation,
    useGetGoodsDetailQuery,
    usePutProductLikesMutation,
    useGetGoodsUserCollectFindNumMutation
} = goodsApi;

