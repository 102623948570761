import { lazy, LazyExoticComponent } from 'react';
import { RouteObject } from 'react-router-dom';

export type RoutesType = {
    path: string,
    element: LazyExoticComponent<any>,
    children?: RoutesType[]
}

export const baseRouteView: RoutesType[] = [
    { path: "/", element: lazy(() => import('containers/PageHome/PageHome2')) }, //首页
    { path: "/nft-details/:id", element: lazy(() => import('containers/NftDetailPage/NftDetailPage')) }, //作品详情
    { path: "/page-commodity", element: lazy(() => import('containers/PageCommodity/PageCommodity')) }, //商品
    { path: '/page-print/:id', element: lazy(() => import('containers/PagePrint/PagePrint')) }, //版画
    { path: '/page-canvas-print/:id', element: lazy(() => import('containers/PageCanvasPrint/PageCanvasPrint')) }, //帆布印花
    { path: '/page-handbag/:id', element: lazy(() => import('containers/PageHandbag/PageHandbag')) }, //手提包
    { path: '/page-tShirt/:id', element: lazy(() => import('containers/PageTShirt/PageTShirt')) }, //T恤
    { path: '/page-sweater/:id', element: lazy(() => import('containers/PageSweater/PageSweater')) }, //卫衣
    { path: '/page-phone-shell/:id', element: lazy(() => import('containers/PagePhoneShell/PagePhoneShell')) }, //手机壳
    { path: '/product-details/:id', element: lazy(() => import('containers/ProductDetails/ProductDetails')) }, //商品详情

    // //
    { path: "/atr-gallery", element: lazy(() => import('containers/PageArtGallery/PageArtGallery')) }, //艺术长廊
    // { path: "/page-collection", element: lazy(() => import('containers/PageCollection/PageCollection')) }, //数字藏品
    { path: "/page-replica", element: lazy(() => import('containers/PageReplica/PageReplica')) }, //复刻品
    { path: "/become-author", element: lazy(() => import('containers/BecomeAuthor/BecomeAuthor')) }, //成为作者
    { path: "/page-upload-item", element: lazy(() => import('containers/PageUploadItem')) }, //上传你的作品
    //
    { path: "/blog", element: lazy(() => import('containers/BlogPage/BlogPage')) }, //博客
    { path: "/blog-single/:id?", element: lazy(() => import('containers/BlogPage/BlogSingle')) }, //个人博客
    // { path: "/user-info", element: lazy(() => import('containers/UserInfo/UserInfo')) }, //个人信息
    //
    { path: "/explore", element: lazy(() => import('containers/Explore/Explore')) }, //探索
    { path: "/common-problem", element: lazy(() => import('containers/CommonProblem/CommonProblem')) }, //常见问题

    { path: "/author", element: lazy(() => import('containers/Author/Author')) }, //作者
    { path: "/page-author/:authorId", element: lazy(() => import('containers/PageAuthor/PageAuthor')) }, //作者介绍
    { path: "/contact", element: lazy(() => import('containers/PageContact/PageContact')) }, //联系我们
    { path: "/about", element: lazy(() => import('containers/PageAbout/PageAbout')) }, // 关于
    { path: "/signup", element: lazy(() => import('containers/PageSignUp/PageSignUp')) }, //注册
    { path: "/forgot-pass", element: lazy(() => import('containers/ForgotPass/ForgotPass')) }, //找回账户密码
    { path: "/login", element: lazy(() => import('containers/PageLogin/PageLogin')) }, //登录
    { path: "/connect-wallet", element: lazy(() => import('containers/PageConnectWallet')) }, //链接钱包
    { path: "/subscription", element: lazy(() => import('containers/PageSubcription/PageSubcription')) }, //订阅
    // { path: "/cart", element: lazy(() => import('containers/Cart/Cart')) }, //购物车
    // { path: '/order-details', element: lazy(() => import('containers/OrderDetails/OrderDetails')) }, //下单详情
    // { path: '/goods-accounts/:type', element: lazy(() => import('containers/GoodsAccounts/GoodsAccounts')) }, //订单结算
    //type buy-now 立即购买  cart-now 购物车购买
    // { path: '/order-submit', element: lazy(() => import('containers/OrderSubmit/OrderSubmit')) }, //提交订单
    { path: "*", element: lazy(() => import('containers/Page404/Page404')) }, //404页面
    { path: '/order-success', element: lazy(() => import('containers/OrderSuccess/OrderSuccess')) },//支付成功回调页面
    { path: '/order-refunds', element: lazy(() => import('containers/OrderRefunds/OrderRefunds')) },//退款页面


];

export const userRoutesView: RoutesType[] = [
    // { path: "/blog-single", element: lazy(() => import('containers/BlogPage/BlogSingle')) }, //个人博客
    { path: "/user-info", element: lazy(() => import('containers/UserInfo/UserInfo')) }, //个人信息
    { path: "/cart", element: lazy(() => import('containers/Cart/Cart')) }, //购物车
    { path: '/order-details', element: lazy(() => import('containers/OrderDetails/OrderDetails')) }, //下单详情
    { path: '/goods-accounts/:type', element: lazy(() => import('containers/GoodsAccounts/GoodsAccounts')) }, //订单结算
    { path: '/order-submit/:type?', element: lazy(() => import('containers/OrderSubmit/OrderSubmit')) }, //提交订单
    { path: '/order-my', element: lazy(() => import('containers/PageMyOrder/PageMyOrder')) },//我的订单


]

export const syncRouter = (route: RoutesType[]): RouteObject[] => {
    const routes: RouteObject[] = []
    route.forEach(item => {
        const path = item.path;
        const Route = item.element
        routes.push({
            path: path,
            element: (<Route />),
            children: item.children && syncRouter(item.children)
        })
    })
    return routes
}


