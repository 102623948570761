import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const api = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api',
        prepareHeaders(headers, api) { //请求头
            // console.log('prepareHeaders', headers);

            const state = api.getState() as { userInfo: { tokenInfo: TokenInfoType } };
            const tokenInfo = state.userInfo.tokenInfo
            headers.set('JBOLTAPI', 'true');
            headers.set('jboltappid', 'jbjstje57xsmolt');
            if (tokenInfo.tokenValue && tokenInfo.tokenName) {
                headers.set(tokenInfo.tokenName, tokenInfo.tokenValue)
            }
            return headers
        },
        timeout: 8000, //设置超时时间 单位毫秒
        jsonContentType: "application/json", //解析格式
      
        // 定义全局的请求拦截器
        // fetchFn: async (url, options) => {
        //     console.log('fetchFn', url);
        //     console.log('fetchFn', options);
        //     const response = await fetch(url, options);
        //     return response;
        // }
        // 定义全局的响应拦截器
        // responseHandler: async (response) => {
        //     if (!response.ok) {
        //         const error = await response.json();
        //         // 返回一个描述错误的对象
        //         return { error };
        //     }

        //     const data = await response.json();
        //     // 返回响应数据
           
        // },
        // 定义全局的错误拦截器
        // invalidatesTags: (result, error, arg, action) => {
        //     console.log('invalidatesTags', result);
        //     console.log('invalidatesTags', error);
        //     console.log('invalidatesTags', arg);
        //     console.log('invalidatesTags', action);
        //     return ['Post']
        // }

    }),


    // extractRehydrationInfo(action, { reducerPath }) {
    //     console.log(action);
    //     console.log(reducerPath);

    //     if (action.type === 'api') return action.payload[reducerPath]

    // },
    // keepUnusedDataFor: 60,
    // refetchOnFocus: true,
    // refetchOnReconnect: true,
    // refetchOnMountOrArgChange: true,
    // serializeQueryArgs:({queryArgs,endpointDefinition,endpointName})=> {
    //     console.log(queryArgs);
    //     console.log(endpointDefinition);
    //     console.log(endpointName);
    //     return ''
    // },

    endpoints: () => ({})
})


export default api; 